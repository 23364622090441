@font-face { font-family: bootsSharp; src: url('/assets/Fonts/BootsSharp-Regular.otf'); } 
/* @font-face { font-family: bootsSharp; font-weight: bold; src: url('/assets/Fonts/BootsSharp-Bold.otf'); }  */
@font-face { font-family: bootsSharpBold; src: url('/assets/Fonts/BootsSharp-Bold.otf'); } 
/**
variables
*/
:root
{
    --bootsLightBlue : rgb(0, 103, 178);
    --bootsDarkBlue : rgb(5, 5, 75);
}
html,
body 
{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: bootsSharp;
    font-size: 16px;
    background-color: var(--bootsLightBlue);
    /* overflow: hidden */
}
/* Debug Text*/
.debug-text 
{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    color: white;
}
.prevent-select 
{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    user-select: none; /* Standard syntax */
}
#canvas
{
    position: relative;
    z-index: 2;
}
/**
SPLASHSCREEN Page
*/
.splashScreen
{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    background-image: url("/assets/images/SplashScreen_bg.png");
    background-attachment: fixed; 
    background-size: cover, 100%;
    background-position: top;
    background-repeat: no-repeat;
    margin: auto;
    z-index: 4;
    transition: filter 1s ease-in-out;
}
/* Use filter to add blur effect */
.soft-blur {
    filter: blur(6px);
}
.splashScreenContentContianer
{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 85%;
    height: 45%;
    margin: 0 auto;
    bottom: 0px;
}
.splashScreen_billie
{
    position: fixed;
    height: 70%;
    top: -5.5%;
    z-index: -10;
}
.splashScreen_billie img
{
    height: 100%;
}
.topCenterScreenLogo
{
    position: fixed;
    width: 12vh;
    top: 8px;
    z-index: 2;
}
.topCenterScreenLogo img
{
    width: 12vh;
    color: white;
}
.button
{
    position: relative;
    border-radius: 10px;
    border: 0;
    width: 100%;
    background: rgb(255, 255, 255);
    color: var(--bootsDarkBlue);
    font-family: bootsSharp;
    text-align: center;
    padding: 5% 3%;
    cursor: pointer;
    z-index: 4;
}
.button:active
{
    background: var(--bootsDarkBlue);
    color: white
}
.buttonMenu
{
    /* margin: clamp(5px, 5%, 28px) 0; */
    margin: clamp(5px, 5%, 28px) 0 clamp(5px, 12%, 54px);
}
.splashScreenText
{
    margin: clamp(5px, 5%, 28px) 0;
    color: white;
    text-align: center;
}
.splashScreenText a
{
    text-decoration: underline;
    color: white;
}
.splashScreenText a:hover
{
    color: var(--bootsLightBlue);
    cursor: pointer;
}
/**
INSTRUCTIONS Page
*/
.instructionsPage
{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    background-image: url("/assets/images/Instructions_bg_v2.png");
    background-attachment: fixed; 
    background-size: cover, 100%;
    background-position: top;
    background-repeat: no-repeat;
    margin: auto;
    visibility: hidden;
    z-index: 2;
}
.instructionsPageContentContianer
{
    height: 83%;
}
.instructionsPageContent
{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;
    row-gap: 24px;
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 0;
}
.list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: start;
}
.list-item span {
    position: absolute;
    left: 0px;
}
.instructionsPageContent img
{
    width: 100%;
}
.instructionsPageContent p
{
    font-size: 20px;
    font-family: bootsSharp;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: 1.3;
    color: white;
    text-align: start;
    margin: 0;
    padding: 0;
    margin-left: 14px;
}
.backButton
{
    position: fixed;
    background: none;
    top: 30px;
    right: 3%;
    width: 5vh;
    height: 5vh;
    padding: 0 0;
}
.backButton img
{
    width: 100%;
}
.backButton:active
{
    background: white;
}
#instructions-launch-btn
{
    margin: clamp(5px, 5%, 28px) 0 clamp(5px, 12%, 54px);
}
/**
GAMEPLAY Page
*/
.gameplayScreen
{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    z-index: 3;
}
.gameplayContentContainer
{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 85%;
    height: 100%;
    /* margin: auto; */
}
.gamePlayTopCenterScreenLogo
{
    position: fixed;
    top: 8px;
    width: 12vh;
    left: calc(50% - 6vh);
}

.gamePlayTopCenterScreenLogo img
{
    width: 100%;
}
.buttonGameplay
{
    background-color: var(--bootsDarkBlue);
    color: white;
    margin: 0.8% 0;
}
.buttonGameplay:active
{
    background-color: white;
    color: var(--bootsDarkBlue);
}
.billieOutline-container
{
    position: fixed;
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    /* align-items: center; */
    justify-content: center;
}
.billieOutline
{
    position: relative;
    top: 75px;
    width: 24vh;
}
.billieOutline img
{
    width: 100%;
}
.keepTogether
{
    white-space: nowrap;
}
#tap-to-place 
{
   margin-bottom:  clamp(5px, 12%, 54px);
}
#progress-ui
{
    position: relative;
    margin: 2.2vh 0;
    width: 100vw;
    left: calc(50% - 50vw);
    background-color: white
}
#progressBar-ui
{
    width: 0%;
    height: 15px;
    background-color: var(--bootsDarkBlue);
}
@media screen and (min-width : 700px) 
{
    /* Splash Page Responsive */
    .splashScreenContentContianer
    {     
        max-width: 800px;
    }
    .splashScreenText
    {
        font-size: 140%;
    }
    .button
    {      
        font-size: 140%;
    }
    /* Gameplay Page Responsive */
    .gameplayContentContainer
    {
        max-width: 800px;
    }
    /* Booking Page Responsive */
}
@media screen and (max-width : 700px) 
{
    /* Splash Page Responsive */
    .splashScreenContentContianer
    {     
        max-width: 600px;
    }
    .splashScreenText
    {
        font-size: calc(90% + 0.3vw);;
    }
    .button
    {
        font-size: calc(100% + 0.3vw);
    }
    /* Instructions Page Responsive */
    .instructionsPageContent {
        gap: 16px;
        row-gap: 20px;
    }
    .instructionsPageContent p
    {
        font-size: 13px;
    }
    /* TODO */
    /* Gameplay Page Responsive */
    .gameplayContentContainer
    {
        max-width: 600px;
    }
    /* Bookign Page Responsive */
}
/**
Cookies
*/
.cookiesBanner-Container
{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* min-height: 300px; */
    bottom: -550px;
    opacity: 0.5;
    z-index: 5;
    border-radius: 10px 10px 0 0;
    transition: all 1s ease-in-out;
}
.cookiesBanner-Div
{
    margin: 1.5rem 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    width: clamp(300px, 85%, 700px);
    padding: 0.5rem;
    text-align: center;
    font-size: 0.8rem;
}
.cookiesBanner-Div h1, p
{
    margin: 0.5rem;
}
.cookiesBanner-Div p
{
    text-align: left;
}
.cookies-button
{
    border-radius: 5px;
    border: 0;
    margin: 0.5rem;
    /* width: 100%; */
    color: rgb(255, 255, 255);
    background: var(--bootsDarkBlue);
    font-family: bootsSharp;
    text-align: center;
    padding: 1.3rem 1.1rem;
    cursor: pointer;
    z-index: 6;
}
.cookies-add {
    bottom: 0px;
    opacity: 1;
  }
#privacy-policy
{
    color: var(--bootsDarkBlue);
}
/**
BOOKING Page
*/
.bookingPage
{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("/assets/images/BookingPage_BG.png");
    background-attachment: fixed; 
    background-size: cover;
    background-position: center;
    visibility: hidden;
    z-index: 3;
}
.bookingContianer
{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 85%;
    max-width: 450px;
    height: 100%;
}
.bookingPage-floating
{
    position: fixed;
    height: 80%;
    top: calc(39% - 40vh);
    z-index: 1;
}
.bookingPage-floating img
{
    height: 100%;
}
.buttonBooking
{
    position: absolute;
    border: 0;
    border-radius: 30px;
    width: 32vh;
    padding: 5% 3%;
    top: calc(83.5% - 40vh);
}
.buttonBooking:active
{
    background-color: white;
    color: var(--bootsDarkBlue);
}
#booking-replay-btn
{
    position: absolute;
    width: 14vh;
    bottom: 30px;
    top: auto;
}