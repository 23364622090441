@font-face {
  font-family: bootsSharp;
  src: url("BootsSharp-Regular.5029c0de.otf");
}

@font-face {
  font-family: bootsSharpBold;
  src: url("BootsSharp-Bold.5a212639.otf");
}

:root {
  --bootsLightBlue: #0067b2;
  --bootsDarkBlue: #05054b;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: var(--bootsLightBlue);
  margin: 0;
  padding: 0;
  font-family: bootsSharp;
  font-size: 16px;
}

.debug-text {
  color: #fff;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
}

.prevent-select {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

#canvas {
  z-index: 2;
  position: relative;
}

.splashScreen {
  width: 100%;
  height: 100%;
  z-index: 4;
  background-image: url("SplashScreen_bg.33d80182.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover, 100%;
  background-attachment: fixed;
  justify-content: center;
  margin: auto;
  transition: filter 1s ease-in-out;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.soft-blur {
  filter: blur(6px);
}

.splashScreenContentContianer {
  width: 85%;
  height: 45%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: fixed;
  bottom: 0;
}

.splashScreen_billie {
  height: 70%;
  z-index: -10;
  position: fixed;
  top: -5.5%;
}

.splashScreen_billie img {
  height: 100%;
}

.topCenterScreenLogo {
  width: 12vh;
  z-index: 2;
  position: fixed;
  top: 8px;
}

.topCenterScreenLogo img {
  width: 12vh;
  color: #fff;
}

.button {
  width: 100%;
  color: var(--bootsDarkBlue);
  text-align: center;
  cursor: pointer;
  z-index: 4;
  background: #fff;
  border: 0;
  border-radius: 10px;
  padding: 5% 3%;
  font-family: bootsSharp;
  position: relative;
}

.button:active {
  background: var(--bootsDarkBlue);
  color: #fff;
}

.buttonMenu {
  margin: clamp(5px, 5%, 28px) 0 clamp(5px, 12%, 54px);
}

.splashScreenText {
  color: #fff;
  text-align: center;
  margin: clamp(5px, 5%, 28px) 0;
}

.splashScreenText a {
  color: #fff;
  text-decoration: underline;
}

.splashScreenText a:hover {
  color: var(--bootsLightBlue);
  cursor: pointer;
}

.instructionsPage {
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 2;
  background-image: url("Instructions_bg_v2.b67882f6.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover, 100%;
  background-attachment: fixed;
  justify-content: center;
  margin: auto;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.instructionsPageContentContianer {
  height: 83%;
}

.instructionsPageContent {
  text-align: center;
  width: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: auto;
  padding: 0;
  display: grid;
  position: relative;
}

.list-item {
  flex-direction: column;
  justify-content: start;
  gap: 16px;
  display: flex;
  position: relative;
}

.list-item span {
  position: absolute;
  left: 0;
}

.instructionsPageContent img {
  width: 100%;
}

.instructionsPageContent p {
  letter-spacing: .28px;
  color: #fff;
  text-align: start;
  margin: 0 0 0 14px;
  padding: 0;
  font-family: bootsSharp;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
}

.backButton {
  width: 5vh;
  height: 5vh;
  background: none;
  padding: 0;
  position: fixed;
  top: 30px;
  right: 3%;
}

.backButton img {
  width: 100%;
}

.backButton:active {
  background: #fff;
}

#instructions-launch-btn {
  margin: clamp(5px, 5%, 28px) 0 clamp(5px, 12%, 54px);
}

.gameplayScreen {
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.gameplayContentContainer {
  width: 85%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: fixed;
}

.gamePlayTopCenterScreenLogo {
  width: 12vh;
  position: fixed;
  top: 8px;
  left: calc(50% - 6vh);
}

.gamePlayTopCenterScreenLogo img {
  width: 100%;
}

.buttonGameplay {
  background-color: var(--bootsDarkBlue);
  color: #fff;
  margin: .8% 0;
}

.buttonGameplay:active {
  color: var(--bootsDarkBlue);
  background-color: #fff;
}

.billieOutline-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  justify-content: center;
  margin: auto;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.billieOutline {
  width: 24vh;
  position: relative;
  top: 75px;
}

.billieOutline img {
  width: 100%;
}

.keepTogether {
  white-space: nowrap;
}

#tap-to-place {
  margin-bottom: clamp(5px, 12%, 54px);
}

#progress-ui {
  width: 100vw;
  background-color: #fff;
  margin: 2.2vh 0;
  position: relative;
  left: calc(50% - 50vw);
}

#progressBar-ui {
  width: 0%;
  height: 15px;
  background-color: var(--bootsDarkBlue);
}

@media screen and (min-width: 700px) {
  .splashScreenContentContianer {
    max-width: 800px;
  }

  .splashScreenText, .button {
    font-size: 140%;
  }

  .gameplayContentContainer {
    max-width: 800px;
  }
}

@media screen and (max-width: 700px) {
  .splashScreenContentContianer {
    max-width: 600px;
  }

  .splashScreenText {
    font-size: calc(90% + .3vw);
  }

  .button {
    font-size: calc(100% + .3vw);
  }

  .instructionsPageContent {
    gap: 20px 16px;
  }

  .instructionsPageContent p {
    font-size: 13px;
  }

  .gameplayContentContainer {
    max-width: 600px;
  }
}

.cookiesBanner-Container {
  width: 100%;
  opacity: .5;
  z-index: 5;
  background-color: #ffffffe6;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
  display: flex;
  position: fixed;
  bottom: -550px;
}

.cookiesBanner-Div {
  width: clamp(300px, 85%, 700px);
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem;
  margin: 1.5rem 0;
  padding: .5rem;
  font-size: .8rem;
  display: flex;
  position: relative;
}

.cookiesBanner-Div h1, p {
  margin: .5rem;
}

.cookiesBanner-Div p {
  text-align: left;
}

.cookies-button {
  color: #fff;
  background: var(--bootsDarkBlue);
  text-align: center;
  cursor: pointer;
  z-index: 6;
  border: 0;
  border-radius: 5px;
  margin: .5rem;
  padding: 1.3rem 1.1rem;
  font-family: bootsSharp;
}

.cookies-add {
  opacity: 1;
  bottom: 0;
}

#privacy-policy {
  color: var(--bootsDarkBlue);
}

.bookingPage {
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 3;
  background-image: url("BookingPage_BG.4e6c1a3a.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  overflow: hidden;
}

.bookingContianer {
  width: 85%;
  max-width: 450px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: relative;
}

.bookingPage-floating {
  height: 80%;
  z-index: 1;
  position: fixed;
  top: calc(39% - 40vh);
}

.bookingPage-floating img {
  height: 100%;
}

.buttonBooking {
  width: 32vh;
  border: 0;
  border-radius: 30px;
  padding: 5% 3%;
  position: absolute;
  top: calc(83.5% - 40vh);
}

.buttonBooking:active {
  color: var(--bootsDarkBlue);
  background-color: #fff;
}

#booking-replay-btn {
  width: 14vh;
  position: absolute;
  top: auto;
  bottom: 30px;
}

.loaded {
  opacity: 0;
  visibility: hidden;
}

.loaded-spinners {
  scale: 0;
}

.loader-loading {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var(--bootsLightBlue);
  transition: opacity .5s, visibility .5s;
  position: absolute;
}

.spinner {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  transition: scale .5s;
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.loader-loading p {
  text-align: center;
  color: #fff;
  text-indent: 0;
  font-family: bootsSharp;
  font-size: 1.5rem;
  position: relative;
  top: calc(50% + 100px);
}

.loader-loading p:before {
  content: "Loading...";
  text-indent: 0;
}

.spinner:after {
  content: "";
  width: 200px;
  height: 200px;
  display: block;
}

.spinner-1:after {
  border: 8px solid #fff;
  border-color: #fff #0000;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  position: absolute;
  top: -8px;
  left: -8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)scale(.85);
  }

  50% {
    transform: rotate(90deg)scale(1.2);
  }

  100% {
    transform: rotate(360deg)scale(.85);
  }
}

.spinner-2:after {
  border: 8px solid #0000;
  border-color: #0000 #fff;
  border-radius: 50%;
  animation: spinInner 2s linear infinite;
  position: absolute;
  top: -8px;
  left: -8px;
}

@keyframes spinInner {
  0% {
    transform: rotate(0deg)scale(.75);
  }

  50% {
    transform: rotate(180deg)scale(1.1);
  }

  100% {
    transform: rotate(360deg)scale(.75);
  }
}

/*# sourceMappingURL=index.880dea18.css.map */
