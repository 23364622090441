/**
LOADER
*/
.loaded
{
    opacity: 0;
    visibility: hidden;
}
.loaded-spinners
{
    scale: 0;
}
.loader-loading
{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: var(--bootsLightBlue);
    transition: opacity 0.5s, visibility 0.5s;
}
.spinner
{
    position: absolute;
    width: 200px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    margin: 0 auto;
    border-radius: 50%;
    transition: scale 0.5s;
}
.loader-loading p 
{
    position: relative;
    text-align: center;
    top: calc(50% + 100px);
    color: white;
    font-family: bootsSharp;
    font-size: 1.5rem;
    text-indent: 0;
}
.loader-loading p::before
{
    content:"Loading...";
    text-indent: 0;
}
.spinner::after
{
    content: "";
    display: block;
    width: 200px;
    height: 200px;
}
.spinner-1::after
{
    /* background-color: white; */
    position: absolute;
    top: -8px;
    left: -8px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: white;
    border-bottom-color: white;
    animation: spin 2s linear infinite;
}
@keyframes spin
{
    0%
        {
            transform: rotate(0deg) scale(0.85)
        }
    50%
        {
            transform: rotate(90deg) scale(1.2);
        }
    100%
        {
            transform: rotate(360deg) scale(0.85)
        }
}
.spinner-2::after
{
    /* background-color: white; */
    position: absolute;
    top: -8px;
    left: -8px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-left-color: white;
    border-right-color: white;
    animation: spinInner 2s linear infinite;
}
@keyframes spinInner
{
    0%
        {
            transform: rotate(0deg) scale(0.75)
        }
    50%
        {
            transform: rotate(180deg) scale(1.1);
        }
    100%
        {
            transform: rotate(360deg) scale(0.75)
        }
}